'use strict';

import '../../styles/pages/index.scss';
import 'swiper/css/bundle';
import 'swiper/css/autoplay';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import wistiaControl from '../partials/wistiaControl';
import initializeYouTubePopup from '../partials/youtube-popup-multiple';

(() => {
  /**
   * @namespace selfIndex
   */
  const selfIndex = {};

  /**
   * @function init
   * @memberof selfIndex
   * @description Initializes all function
   */
  selfIndex.init = () => {
    selfIndex.sliders();

    if(document.documentElement.lang === 'zh') {
      selfIndex.videoWistia();
    } else {
      initializeYouTubePopup('pgvz1qxcMy4', 1);
    }
  };

  /**
   * @function sliders
   * @memberof selfIndex
   * @description Initializes sliders
   */
  selfIndex.sliders = () => {
    /**
     * Configuration for the companies' slider
     * @type {object}
     */
    const configCompaniesSlider = {
      modules: [Autoplay],
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      slidesPerView: 'auto',
      speed: 2800,
      loop: true,
      allowTouchMove: false,
    };

    new Swiper('.swiper-companies', configCompaniesSlider);

    /**
     * Configuration for the first Swiper instance in the "integrations" section
     * @type {object}
     */
    const swiperIntegrationsFirstConfig = {
      modules: [Autoplay],
      speed: 4000,
      slidesPerView: 'auto',
      allowTouchMove: false,
      spaceBetween: 0,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      loop: true,
    };

    /**
     * Additional configuration object for the second Swiper instance in the "integrations" section
     * @type {object}
     */
    const swiperIntegrationsSecondConfig = {
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
        reverseDirection: true,
      }
    };

    new Swiper('.integration-box__logos-1', swiperIntegrationsFirstConfig);
    new Swiper('.integration-box__logos-2', { ...swiperIntegrationsFirstConfig, ...swiperIntegrationsSecondConfig });

    /**
     * Configuration for the extensions slider
     * @type {object}
     */
    const configExtensionsSlider = {
      modules: [Pagination, EffectFade],
      slidesPerView: 1,
      speed: 300,
      allowTouchMove: false,
      effect: 'fade',
      pagination: {
        el: '.swiper-extensions__pagination',
        clickable: true,
        hideOnClick: false,
        renderBullet: (index, className) => {
          let paginationElem = document.querySelector(`.swiper-extensions .swiper-slide:nth-child(${index + 1})`);
          let title = paginationElem.dataset.pagination;
          paginationElem = `<div class="${className}">${title}</div>`;

          return paginationElem;
        },
      },
      on: {
        beforeTransitionStart: (swiper) => {
          let nextSlide = swiper.slides[swiper.activeIndex],
            raitingbBlock = document.querySelector('.extensions__raiting');

          document.querySelector('.extensions__text').innerText = nextSlide.dataset.text;
          document.querySelector('.extensions__middle-area .btn-main span').innerText = nextSlide.dataset.btn;
          document.querySelector('.extensions__middle-area .btn-main').setAttribute('href', nextSlide.dataset.btnUrl);

          if (nextSlide.hasAttribute('data-r1')) {
            raitingbBlock.innerHTML = `<b>${nextSlide.dataset.r1} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" fill="none" viewBox="0 0 16 15"><path fill="#2E2151" d="m8 0 2.116 5.088 5.492.44-4.184 3.584 1.278 5.36L8 11.6l-4.702 2.872 1.278-5.36L.392 5.528l5.492-.44L8 0Z"/></svg></b><span>${nextSlide.dataset.r2}</span>`;
          } else {
            raitingbBlock.innerHTML = '';
          }
        },
      },
    };

    new Swiper('.swiper-extensions', configExtensionsSlider);

    /**
     * Flag indicating whether the animation is currently in progress.
     * @type {boolean}
     */
    let ticking = false;

    /**
     * The latest known horizontal scroll position.
     * @type {number}
     */
    let latestKnownScrollX = 0;

    /**
     * The pagination container element.
     * @type {HTMLElement}
     */
    let extensionsPagination = document.querySelector('.swiper-extensions__pagination');

    /**
     * Updates the position of the block.
     */
    function updatePositionBlock() {
      ticking = false;
      extensionsPagination.scrollLeft = latestKnownScrollX;
    }

    /**
     * Handles the scroll event on the block.
     * @param {Event} e - The scroll event object.
     */
    function onScrollBlock(e) {
      latestKnownScrollX = e.currentTarget.scrollLeft;
      requestTickBlock();
    }

    /**
     * Requests a tick for animation.
     */
    function requestTickBlock() {
      if (!ticking) {
        window.requestAnimationFrame(updatePositionBlock);
      }
      ticking = true;
    }

    /**
     * Animates scrolling of an element from the current scroll position to the target scroll position.
     * @param {HTMLElement} element - The element to be scrolled.
     * @param {number} currentScroll - The current scroll position.
     * @param {number} targetScroll - The target scroll position.
     */
    function animateScroll(element, currentScroll, targetScroll) {
      /**
       * Function representing a step in the animation.
       * This function adjusts the scroll position of an element gradually towards a target position.
       */
      const step = () => {
        currentScroll += (targetScroll - currentScroll) * 0.1; // Adjust the smoothness factor as needed
        element.scrollLeft = currentScroll;

        if (Math.abs(targetScroll - currentScroll) > 1) {
          window.requestAnimationFrame(step);
        } else {
          element.scrollLeft = targetScroll;
          ticking = false;
        }
      };
      if (!ticking) {
        ticking = true;
        window.requestAnimationFrame(step);
      }
    }

    // Add event listener for scroll on the block
    extensionsPagination.addEventListener('scroll', onScrollBlock, false);

    // Add event listener for click on scroll button
    document.querySelector('.swiper-extensions__pagination-scroll-btn').addEventListener('click', function() {
      let currentScroll = extensionsPagination.scrollLeft;
      let targetScroll = currentScroll + 100;

      animateScroll(extensionsPagination, currentScroll, targetScroll);
    });
  };

  /**
   * @function videoWistia
   * @memberof selfIndex
   * @description Initializes wistia
   */
  selfIndex.videoWistia = () => {
    /**
     * @type {HTMLElement}
     * @description Button for starting the demo video (first one)
     */
    const btnDemoVideo = document.querySelector('#watchDemo');

    /**
     * @type {HTMLElement}
     * @description Get the preview video element
     */
    const previewVideo = document.querySelector('#watchDemo video');

    wistiaControl(btnDemoVideo, '1ft7syglwi', previewVideo);
  };

  /**
   * @listens global#DOMContentLoaded
   * @description Event handler for DOMContentLoaded to initialize the page
   */
  document.addEventListener('DOMContentLoaded', () => selfIndex.init());
})();
