/**
 * @module wistiaControlModule
 * @description wistia
 */

/**
 * @function wistiaControl
 * @param {HTMLElement} btn - The element (button) that triggers video playback
 * @param {string} idVideo - The identifier of the Wistia video
 * @param {HTMLVideoElement|null} [previewVideo] - The optional preview video element.
 * @description Controls the Wistia video
 */
export default function wistiaControl(btn, idVideo, previewVideo = null) {
  btn.addEventListener('click', function () {
    const demoVideo = Wistia.api(idVideo);
    /**
     * @type {HTMLElement}
     * @description Button for closing the demo video
     */
    const closeBtnWistia = document.querySelector('#popover_preview_embed_popover_popover_close_button');

    // Pause the preview video when the popover opens, if provided
    if (previewVideo && previewVideo instanceof HTMLVideoElement) {
      previewVideo.pause();
    }

    demoVideo?.play();
    closeBtnWistia.classList.add('active');
    demoVideo.bind('popoverhide', () => {
      closeBtnWistia.classList.remove('active');

      // Resume the preview video playback when the popover is closed, if provided
      if (previewVideo && previewVideo instanceof HTMLVideoElement) {
        previewVideo.play();
      }
    });
  });
}

