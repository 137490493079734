'use strict';

/**
 * @function calculatePlayerSize
 * @returns {object} - An object with calculated values for the player's width and height
 * @description Calculates player size proportions with
 * 16:9 aspect ratio depending on screen size with size restrictions
 */
function calculatePlayerSize() {
  const aspectRatio = 16 / 9;

  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  let baseWidth = screenWidth * 0.7;
  let baseHeight = baseWidth / aspectRatio;

  const minHeight = 200;
  const maxHeightPercent = 80;

  if (baseHeight < minHeight) {
    baseHeight = minHeight;
    baseWidth = baseHeight * aspectRatio;
  }

  const maxHeight = screenHeight * (maxHeightPercent / 100);
  if (baseHeight > maxHeight) {
    baseHeight = maxHeight;
    baseWidth = baseHeight * aspectRatio;
  }

  return { width: baseWidth, height: baseHeight };
}

/**
 * @function includeYtIframApi
 * @returns {void}
 * @description Includes the YouTube iframe API script if not already present.
 */
function includeYtIframApi() {
  // Creating and appending a script tag for YouTube iframe API
  // Check if the YouTube iframe API script is not already present
  const scriptExists = [...document.querySelectorAll('script')]
    .some(script => script.src === 'https://www.youtube.com/iframe_api');

  if (!scriptExists) {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
}

/**
 * @function toggleYouTubePopup
 * @param {string} popupId - Unique identifier for the popup
 * @description Toggle YouTube popup visibility
 */
function toggleYouTubePopup(popupId) {
  document.querySelector(`.youtube-popup_${popupId}`).classList.toggle('show');
  document.documentElement.classList.toggle('overflow-hidden');
  document.body.classList.toggle('overflow-hidden');
}

/**
 * @function initializeYouTubePopup
 * @param {string} idVideo - Id youtube video
 * @param {string} popupId - Unique identifier for the popup
 * @param {Function} [onReadyCallback] - Optional callback function to be called when the player is ready
 * @param {Function} [onCloseCallback] - Optional callback function to be called when the popup is closed
 * @description Initialize YouTubePopup
 */
export default function initializeYouTubePopup(idVideo, popupId, onReadyCallback, onCloseCallback) {

  includeYtIframApi();

  let player;

  /**
   * @function onYouTubeIframeAPIReady
   * @description Embeds YouTube player in block and added events
   */
  function onYouTubeIframeAPIReady() {
    player = new YT.Player(`playerYoutubeEmbed_${popupId}`, {
      height: calculatePlayerSize().height,
      width: calculatePlayerSize().width,
      videoId: idVideo,
      modestbranding: 1,
      playerVars: {
        controls: 1,
      },
      events: {
        'onReady': onPlayerReady,
      },
    });
  }

  /**
   * @function
   * @param {Event} event - Object of onReady event
   * @description Callback function when YouTube player is ready
   */
  function onPlayerReady(event) {
    toggleYouTubePopup(popupId);
    event.target.playVideo();

    window.addEventListener('resize', () => {
      event.target.g.width = calculatePlayerSize().width;
      event.target.g.height = calculatePlayerSize().height;
    });

    if (onReadyCallback && typeof onReadyCallback === 'function') {
      onReadyCallback(event);
    }
  }

  /**
   * @function closeYouTubePopup
   * @param {string} popupId - Unique identifier for the popup
   * @description Event listeners for closing the YouTube popup
   */
  function closeYouTubePopup(popupId) {
    if (player) {
      player.stopVideo();
      toggleYouTubePopup(popupId);

      if (onCloseCallback && typeof onCloseCallback === 'function') {
        onCloseCallback();
      }
    }
  }

  document.querySelectorAll(`.js-youtube-popup-close_${popupId}, .youtube-popup__overlay_${popupId}`).forEach((el) => {
    el.addEventListener('click', () => closeYouTubePopup(popupId));
  });

  /**
   * @function eventsYouTubePopup
   * @param {string} popupId - Unique identifier for the popup
   * @description Event listeners for opening the YouTube popup
   */
  function eventsYouTubePopup(popupId) {
    if (!player) {
      onYouTubeIframeAPIReady();
    } else {
      toggleYouTubePopup(popupId);
      player.playVideo();

      if (onReadyCallback && typeof onReadyCallback === 'function') {
        onReadyCallback();
      }
    }
  }

  const btnsDemoVideo = document.querySelectorAll(`.js-youtube-video-popup_${popupId}`);
  btnsDemoVideo.forEach((el) => {
    el.addEventListener('click', () => eventsYouTubePopup(popupId));
  });
}
